import { useEffect } from 'react';
import Cookies from 'js-cookie'
import { useAppSelector } from 'hooks/store';
import { PRICE_ID_COOKIE, PURCHASE_COOKIE, SINGLE_TRACK_COOKIE, TRIAL_COOKIE } from 'constants/cookies-names';
import { useDispatch } from 'react-redux';
import { trackSingleTrackPurchase, trackSubscriptionPurchase, trackTrialStarted } from '../redux/metrics/actions';

export function useHandlePurchaseEvent() {
  const loggedIn: any = useAppSelector(state => state.loggedIn);
  const subscriptions = useAppSelector(state => state.pricingInfo.data.subscription);
  const dispatch = useDispatch();

  useEffect(() => {
    const isPurchaseHappened = Cookies.get(PURCHASE_COOKIE);
    const priceId = Cookies.get(PRICE_ID_COOKIE);
    const trial = Cookies.get(TRIAL_COOKIE);
    const isSingleTrackCookie = Cookies.get(SINGLE_TRACK_COOKIE);

    if (
      isPurchaseHappened === 'true' &&
      loggedIn &&
      subscriptions.length > 0 &&
      (
        window.rewardful ||
        process.env.REACT_APP_ENV !== 'production'
      )
    ) {
      console.log(`try call window.rewardful with params: convert, email = ${loggedIn.email}`);

      if (priceId) {
        if (isSingleTrackCookie === 'true') {
          dispatch(trackSingleTrackPurchase(priceId));
        } else if (trial && trial !== 'undefined') {
          dispatch(trackTrialStarted(priceId, trial));
        } else {
          dispatch(trackSubscriptionPurchase(priceId));
        }

        Cookies.remove(SINGLE_TRACK_COOKIE);
        Cookies.remove(TRIAL_COOKIE);
        Cookies.remove(PRICE_ID_COOKIE);
      }

      Cookies.remove(PURCHASE_COOKIE);

      if (window.rewardful) {
        console.log('call window.rewardful');
        window.rewardful('convert', { email: loggedIn.email });
      }
    }
  }, [loggedIn, subscriptions, dispatch]);
}
