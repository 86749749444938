import React from 'react';
import { LogoFooter } from '../icons/logo-footer';
import { MUBERT_API_URL } from 'constants/common';
import { NavLink } from 'layout/components/nav-link';
import useTranslation from 'hooks/useTranslations';
import './FooterLogoBlock.scss';

export default function FooterLogoBlock() {
  const translate = useTranslation();
  return (
    <div className='logo-block'>
      <div className='logo-block__logo'>
        <div className='logo-block__logo-wrapper'>
          <LogoFooter />
        </div>
        <p className='logo-block__adress'>
          Mubert Inc
          <br />
          8 The Green Dover
          <br />
          Delaware US 19901​
          <br />
          <a href='mailto:business@mubert.com'>business@mubert.com</a>
        </p>
      </div>
      <div className='logo-block__columns'>
        <div className='logo-block__column'>
          <span className='logo-block__column_heading'>{translate('Products')}</span>
          <ul className='logo-block__column_list'>
            <li>
              <NavLink to='/render' activeClassName='active' replace>
                <span className='link-text'>{translate('Mubert Render')}</span>
              </NavLink>
            </li>
            <li>
              <a href='https://beta.mubert.com/' target='_blank' rel='noopener noreferrer'>
                <span className='link-text'>{translate('Mubert Studio')}</span>
              </a>
            </li>
            <li>
              <a href={MUBERT_API_URL} target='_blank' rel='noopener noreferrer nofollow'>
                <span className='link-text'>Mubert API</span>
              </a>
            </li>
            <li>
              <a
                href='https://playapp.mubert.com/?utm_source=mubert.com&utm_medium=cpc&utm_content=mubert_play_footer'
                target='_blank'
                rel='noopener noreferrer nofollow'
              >
                <span className='link-text'>{translate('Mubert Play')}</span>
              </a>
            </li>
            <li>
              <NavLink to='/render/streamers' activeClassName='active' replace>
                <span className='link-text'>{translate('Mubert for Streamers')}</span>
              </NavLink>
            </li>
            <li>
              <a
                href='https://business.mubert.com/'
                target='_blank'
                rel='noopener noreferrer nofollow'
              >
                <span className='link-text'>{translate('Mubert Business')}</span>
              </a>
            </li>
          </ul>
        </div>
        <div className='logo-block__column'>
          <span className='logo-block__column_heading'>{translate('Company')}</span>
          <ul className='logo-block__column_list'>
            <li>
              <a href='/blog/'>
                <span className='link-text'>{translate('Blog')}</span>
              </a>
            </li>
            <li>
              <NavLink to='/about-us' activeClassName='active' replace>
                <span className='link-text'>{translate('About us')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/render/faq' activeClassName='active' replace>
                <span className='link-text' translate='no'>
                  FAQ
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/contact'>
                <span className='link-text'>{translate('Contact')}</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
