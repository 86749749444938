import React from 'react';
import { usePathPrefix } from 'hooks/useLanguage';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

export const Link = ({ to, ...props }: LinkProps) => {
    const prefix = usePathPrefix();

    const updatedTo = typeof to === 'string'
        ? `${prefix}${to}`
        : to;

    return <ReactRouterLink {...props} to={updatedTo} />
};