import React from 'react';
import classNames from 'utils/class-names';
import './AuthBtns.scss';
import { NewButton } from '../new-button';
import { usePush } from 'hooks/usePush';

export default function AuthBtns({
  onClick
}: {
  onClick?: () => void;
}) {
  const className = classNames({
    'auth-btns': true,
  });

  const push = usePush();

  const onLoginClick = () => {
    if (onClick) {
      onClick();
    }
    push('/render/sign-in');
  };

  const onSignUpClick = () => {
    if (onClick) {
      onClick();
    }
    push('/render/sign-up');
  };
  return (
    <div className={className}>
      <NewButton onClick={onLoginClick} size='s'>
        Log In
      </NewButton>
      <NewButton onClick={onSignUpClick} size='s' newgradient>
        Sign Up
      </NewButton>
    </div>
  );
}
