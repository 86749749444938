import { useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

export function useIsBodyScrolled(pixel = 4) {
  const [isBodyScrolled, setIsBodyScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.document.body.scrollTop > pixel && !isBodyScrolled) {
        setIsBodyScrolled(true);
      } else if (window.document.body.scrollTop <= pixel && isBodyScrolled) {
        setIsBodyScrolled(false);
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isBodyScrolled, setIsBodyScrolled]);

  return isBodyScrolled;
}
