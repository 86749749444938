import React from 'react';
import { Select } from 'layout/components/select';
import { useMediaQuery } from 'react-responsive';
import classNames from 'utils/class-names';
import './app-select.scss';

type AppSelectProps = {
  options: Array<{ title: React.ReactNode; value: any }>;
  onChange?: (value: any) => void;
  onSearch?: (value: any) => void;
  filterOption?: (input: string, option: any) => boolean;
  value?: any;
  error?: boolean;
  showSearch?: boolean;
  onFocus?: (e: React.FocusEvent<any>) => void;
  onSelect?: (value: any) => void;
  className?: string;
  isNewSelector?: boolean;
  renderDropDownToBody?: boolean;
  customArrow?: React.ReactNode;
  style?: React.CSSProperties;
};

const IS_BROWSER = typeof window !== 'undefined';

function AppSelectInternal(
  {
    options,
    onChange,
    onSearch,
    onSelect,
    onFocus,
    filterOption,
    value,
    showSearch,
    error,
    style,
    className: propClassName,
    isNewSelector,
    customArrow,
    renderDropDownToBody
  }: AppSelectProps,
  ref
) {
  const isMobileOrLaptop = useMediaQuery(
    { maxWidth: 991 } // `device` prop
  );

  const className = classNames({
    'app-mobile-select': true,
    [propClassName]: !!propClassName,
    'app-mobile-select_error': !!error
  });

  return (
    <div className={className} style={style}>
      <Select
        showSearch={showSearch}
        getPopupContainer={renderDropDownToBody ? undefined : (trigger) => trigger.parentNode}
        onChange={onChange}
        onSearch={onSearch}
        onFocus={onFocus}
        filterOption={filterOption}
        value={value}
        onSelect={onSelect}
        className={isNewSelector ? 'app-select-new' : ''}
        dropdownClassName={isNewSelector ? 'app-select-new app-select-new__dropdown' : ''}
        // TODO: add default suffixIcon
        suffixIcon={customArrow ? customArrow : undefined}
      >
        {options.map((item) => (
          <Select.Option style={{ fontWeight: 'normal' }} value={item.value} key={item.value}>
            <span>{item.title}</span>
          </Select.Option>
        ))}
      </Select>
      {IS_BROWSER && isMobileOrLaptop && (
        <select
          className='app-mobile-select__native-select'
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }

            if (onSelect) {
              onSelect(e.target.value);
            }
          }}
          value={value}
        >
          {options.map((item) => (
            <option value={item.value} key={item.value}>
              {item.title}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}

export const AppSelect = React.forwardRef(AppSelectInternal);
