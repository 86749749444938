import React from 'react';
import { useDispatch } from 'react-redux';
import {NotificationManager} from '../notification-manager';
import { useSelectNotifications } from '../../../../redux/notifications/selectors';
import { removeNotification } from '../../../../redux/notifications/actions';

export function Notifications() {
  const notifications = useSelectNotifications();
  const dispatch = useDispatch();
  const onRemoveNotification = (id) => {
    dispatch(removeNotification(id));
  };

  return (
    <NotificationManager
      notifications={ notifications }
      stickTo='left'
      onRemoveNotification={ onRemoveNotification }
    />
  )
}
