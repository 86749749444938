export function setCookie(name: string, value: string) {
    // Expiry date set to a far future date (in milliseconds)
    var expires = new Date('9999-12-31').toUTCString();
    document.cookie = name + '=' + value + '; expires=' + expires + '; path=/';
}

export function getCookie(name: string) {
    var cookies = document.cookie.split(';');
    
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        
        if (cookie.indexOf(name + '=') === 0) {
            return cookie.substring(name.length + 1);
        }
    }
    
    // If cookie not found, return null
    return null;
}
