import React, { forwardRef } from 'react';
import { usePathPrefix } from 'hooks/useLanguage';
import { NavLink as ReactRouterNavLink, NavLinkProps } from 'react-router-dom';

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
    ({ to, ...props }, ref) => {
        const prefix = usePathPrefix();

        const updatedTo = typeof to === 'string'
            ? `${prefix}${to}`
            : to;

        return <ReactRouterNavLink {...props} to={updatedTo} ref={ref} />;
    }
);