import React from 'react';
import { IconBase } from '../tracklist/IconBase';
import { BurgerIcon } from '../icons/burger-icon';
import './HeaderToggle.scss';
import { BurgerClose } from '../icons/burger-close/burger-close';


type HeaderToggleProps = {
  onClick?: (e: React.MouseEvent) => void;
  opened?: boolean;
};

export function HeaderToggle({ onClick, opened }: HeaderToggleProps) {
  return (
    <IconBase onClick={onClick} className='header-toggle'>
      {
        opened
          ? <BurgerClose />
          : <BurgerIcon />
      }
    </IconBase>
  );
}
HeaderToggle.displayName = 'HeaderToggle';
