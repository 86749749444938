import React from 'react';
import './Item.scss';
import classNames from '../../../utils/class-names';

type LinkItemProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

export function Item({ children, disabled, onClick }: LinkItemProps) {
  const classes = classNames({
    'app-menu-item': true,
    'app-menu-item__disabled': !!disabled
  });

  return (
    <li className={classes} onClick={onClick}>
      {children}
    </li>
  );
}
