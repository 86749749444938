import React, { CSSProperties } from 'react';
import classNames from 'utils/class-names';
import './new-button.scss';

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  className?: string;
  id?: string;
  htmlType?: 'submit' | 'reset' | 'button';
  type?: 'primary' | 'secondary' | 'active';
  block?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  disabled?: boolean;
  size?: 's' | 'm';
  loading?: boolean;
  title?: string;
  transparent?: boolean;
  gradient?: boolean;
  newgradient?: boolean,
  accentColor?: boolean
};

function ButtonComponent({
  children,
  onClick,
  className: propClassName,
  htmlType,
  block,
  style,
  icon,
  disabled,
  size = 'm',
  loading = false,
  title,
  type,
  transparent,
  gradient,
  newgradient,
  accentColor,
  id
}: ButtonProps, ref) {
  const className = classNames({
    'new-button': true,
    'new-button_block': block,
    [propClassName]: !!propClassName,
    'new-button_disabled': disabled || loading,
    'new-button_loading': loading,
    'new-button_active': type === 'active',
    [`new-button_size_${size}`]: !!size,
    'new-button_transparent': transparent,
    'new-button_accent': accentColor,
    'new-button_gradient': gradient,
    'new-button_newgradient': newgradient
  });

  return (
    <button
      ref={ref}
      id={id}
      title={title}
      style={style}
      type={htmlType}
      className={className}
      onClick={(e) => {
        if (!disabled && !loading && onClick) {
          onClick(e);
        }
      }}
      disabled={disabled || loading}
    >
      {icon}
      <span>{children}</span>
    </button>
  );
}

export const NewButton = React.forwardRef(ButtonComponent)

NewButton.displayName = 'Button';
