export enum ModalNames {
    Purchase,
    ChannelInfo,
    AttributionInfo,
    UnauthorizedTracksRestrictions,
    DownloadTrack,
    FreePlanTracksRestrictions,
    LimitExceeded,
    TrackDuration,
    ConfirmDeletion,
    PurchaseArtist,
    LogIn,
    GenerateByImageModal,
    TurstileModal,
    EditTrackName,
    UpgradeSubscription,
    WhatsNewModal,
    ConfirmModal,
    VerificationCode,
}
