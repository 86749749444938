import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

type CloseIconProps = {
  className?: string;
  onClick?: () => void;
};

export function CloseIcon({ className, onClick }: CloseIconProps) {
  const isDarkMode = useSelector((state: IAppState) => state.settings.isDarkTheme);

  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onClick={onClick}
    >
      <circle cx='12.5' cy='12.5' r='12.5' fill='#F5F5F5' />
      <rect
        x='7.55078'
        y='16.7422'
        width='13'
        height='1'
        transform='rotate(-45 7.55078 16.7422)'
        fill={isDarkMode ? 'white' : '#414950'}
      />
      <rect
        x='16.7422'
        y='17.4492'
        width='13'
        height='1'
        transform='rotate(-135 16.7422 17.4492)'
        fill={isDarkMode ? 'white' : '#414950'}
      />
    </svg>
  );
}
