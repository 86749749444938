import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

export function BurgerClose() {
  const isDarkMode = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const strokeColor = isDarkMode ? '#008dff' : '#000DFF';

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M18 6L6 18'
        stroke={strokeColor}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 6L18 18'
        stroke={strokeColor}
        stroke-width='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
