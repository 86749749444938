import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'hooks/useLocation';
import { setLanguage, toggleTheme } from '../../../redux/settings/actions';
import SocialIcons from './SocialIcons';
import { ToggleButton } from 'ui/toggle-button';
import { AppSelect } from '../app-select';
import { LANGUAGES_OPTIONS } from 'constants/languages';
import { ExpandIcon } from '../icons/expand-icon';
import useTranslation from 'hooks/useTranslations';
import { useIsNightModeAvailable } from 'hooks/useIsNightModeAvailable';
import { useLanguage } from 'hooks/useLanguage';
import { IAppState } from 'interfaces';
import './FooterSettings.scss';

export default function FooterSettings() {
  const translate = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isNighModeAvailable = useIsNightModeAvailable();
  const isLanguageSelectorAvailable = !pathname.startsWith('/lp');
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const language = useLanguage();
  function handleToggleTheme() {
    dispatch(toggleTheme());
  }

  const handleLanguageSelect = (value) => {
    dispatch(setLanguage(value));
  };

  return (
    <div className='footer-settings'>
      <div className='footer-settings__selectors'>
        {isLanguageSelectorAvailable && (
          <div className='footer-settings__selectors_languages'>
            <AppSelect
              options={LANGUAGES_OPTIONS}
              value={language}
              onChange={handleLanguageSelect} 
              isNewSelector={true}
              customArrow={<ExpandIcon />}
            />
          </div>
        )}
        {isNighModeAvailable && (
          <div className='footer-settings__selectors_night-mode'>
            {translate('Night mode')}
            <ToggleButton
              onChange={handleToggleTheme}
              isChecked={isDarkTheme}
              size='M'
              id='nightModeToggle'
            />
          </div>
        )}
      </div>
      <div className='footer-settings__socials'>
        <SocialIcons />
      </div>
    </div>
  );
}
