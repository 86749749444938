import React from 'react';
import './spinning-circle.scss';

export function SpinningCircle() {
  return (
    <div className='spinning-circle'>
      <div className="spinning-circle__border" />
      <div className="spinning-circle__core" />
    </div>
  );
}
