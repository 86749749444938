import React from 'react';
import classNames from 'utils/class-names';
import './Nav.scss';
import {LinkItem} from './LinkItem';

type NavProps = {
  children: React.ReactNode;
  className?: string;
}

export default function Nav({children, className}: NavProps) {
  const classes = classNames({
    'app-nav': true,
    [className]: !!className
  });

  return (
    <nav className={classes}>
      <ul>
        {children}
      </ul>
    </nav>
  )
}
Nav.LinkItem = LinkItem;
