import React from 'react';
import RcSelect, {SelectProps as RcSelectProps, Option } from 'rc-select';
import './select.scss';

type SelectProps<VT = any> = RcSelectProps<any>;

function InternalSelect({
  listHeight = 256,
  listItemHeight = 24,
  className,
  dropdownClassName,
  getPopupContainer,
  suffixIcon,
  ...selectProps
}: SelectProps, ref) {
  return (
    <RcSelect
      ref={ref as any}
      {...selectProps}
      transitionName='app-slide-up'
      listHeight={listHeight}
      listItemHeight={listItemHeight}
      prefixCls='app-select'
      className={className}
      getPopupContainer={getPopupContainer}
      dropdownClassName={dropdownClassName}
      suffixIcon={suffixIcon}
    />
  );
}

const SelectRef = React.forwardRef(InternalSelect) as <VT extends any>(
  props: SelectProps<VT>,
) => React.ReactElement;

type InternalSelectType = typeof SelectRef;

interface SelectInterface extends InternalSelectType {
  SECRET_COMBOBOX_MODE_DO_NOT_USE: string;
  Option: typeof Option;
}

export const Select = SelectRef as SelectInterface;

Select.Option = Option;
