import { useCallback } from 'react';
import { history } from '../redux/store';
import { usePathPrefix } from './useLanguage';
import { LocationDescriptor } from 'history';

export function usePush() {
  const prefix = usePathPrefix();

  return useCallback(
    (path: string | LocationDescriptor<any>) => {
      if (typeof path === 'string') {
        history.push(`${prefix}${path}`);
      } else {
        history.push({
            ...path,
            pathname: `${prefix}${path.pathname}`
        })
      }
    },
    [prefix]
  );
}

export function useReplace() {
    const prefix = usePathPrefix();
  
    return useCallback(
      (path: string | LocationDescriptor<any>) => {
        if (typeof path === 'string') {
          history.replace(`${prefix}${path}`);
        } else {
          history.replace({
              ...path,
              pathname: `${prefix}${path.pathname}`
          })
        }
      },
      [prefix]
    );
  }
  