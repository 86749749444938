import {api} from 'api';
import { storage } from 'utils/storage';

export function useLogout() {
  const logout = async () => {
    try {
      storage.removeItem('generated-track-ids');
      await api.logout();
    } finally {
      window.location.reload();
    }
  };

  return logout;
}
