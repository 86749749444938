import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'layout/components/link';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'interfaces/app-state';
import { useLogout } from 'hooks/useLogout';
import './ProfileDropdownContent.scss';
import { ProfileAvatar } from '../../profile-avatar';
import {
  useSelectBestHighestAvailableSubscription,
  useSelectPricingInfo
} from '../../../../redux/pricing-info/selectors';
import { Button } from 'layout/components/app-button';
import { ToggleButton } from 'ui/toggle-button';
import { toggleTheme } from '../../../../redux/settings/actions';
import { useIsNightModeAvailable } from 'hooks/useIsNightModeAvailable';
import useTranslation from 'hooks/useTranslations';
import { LinkButton } from 'layout/components/link-button';
import { usePush } from 'hooks/usePush';

type ProfileDropdownContentProps = {
  onClick: () => void;
};

export function ProfileDropdownContent({ onClick }: ProfileDropdownContentProps) {
  const { isLoading: isPricingLoading } = useSelectPricingInfo();
  const subscription = useSelectBestHighestAvailableSubscription();
  const loggedIn = useSelector((state: IAppState) => state.loggedIn);
  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const dispatch = useDispatch();
  const translate = useTranslation();
  const push = usePush();

  const logout = useLogout();
  const { pathname } = useLocation();
  const isRenderRoute = pathname.startsWith('/render');
  const isWithSubscriptions = Boolean(
    loggedIn &&
      loggedIn['subs'] &&
      Object.keys(loggedIn['subs']).filter((key) => key !== 'ambassador').length
  );
  const isNightModeAvailable = useIsNightModeAvailable();
  const showPremium = !isWithSubscriptions && (loggedIn || isRenderRoute);

  function handleToggleTheme() {
    dispatch(toggleTheme());
  }

  function handleClick() {
    push('/render/pricing');
    onClick();
  }

  function handleProfileClick() {
    push('/render/profile');
    onClick();
  }

  return (
    <div className='avatar-dropdown'>
      <div className='avatar-dropdown__cover' onClick={handleProfileClick}>
        <ProfileAvatar className='avatar-dropdown__avatar' />
        {!isPricingLoading && (
          <p className='avatar-dropdown__name'>
            {subscription?.name ? subscription.name : `${translate('No subscription')}`}
          </p>
        )}
        <p className='avatar-dropdown__email'>{loggedIn['email']}</p>
      </div>
      <div className='avatar-dropdown__menu'>
        {showPremium && (
          <li className='app-menu-link-item__premium'>
            <Button onClick={handleClick} className='app-menu-link-item__button'>
              {translate('Go Premium')}
            </Button>
          </li>
        )}
        <li className='app-menu-link-item'>
          <Link to='/render/my-downloads' onClick={onClick}>
            {translate('My Downloads')}
          </Link>
        </li>
        <li className='app-menu-link-item'>
          <Link to='/render/pricing' onClick={onClick}>
            {translate('My Subscription')}
          </Link>
        </li>
        <li className='app-menu-link-item'>
          <Link to='/render/profile' onClick={onClick}>
            {translate('My Profile')}
          </Link>
        </li>
        {isNightModeAvailable && (
          <li className='app-menu-link-item'>
            {translate('Night mode')}
            <ToggleButton
              onChange={handleToggleTheme}
              isChecked={isDarkTheme}
              size='S'
              id='nightModeToggle'
            />
          </li>
        )}
        <li className='app-menu-link-item'>
          <LinkButton
            onClick={() => {
              if (pathname.endsWith('/profile')) {
                push('/render');
                logout();
                return;
              }
              logout();
            }}
          >
            Sign out
          </LinkButton>
        </li>
      </div>
    </div>
  );
}
