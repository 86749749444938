import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'layout/components/link';
import { addNotification, removeNotification } from '../redux/notifications/actions';
import { useSelectNotifications } from '../redux/notifications/selectors';
import { storage } from 'utils/storage';

export function useShowCookieNotification() {
  const dispatch = useDispatch();
  const notifications = useSelectNotifications();
  useEffect(() => {
    const isPoliciesViewed = storage.getItem('view-policies');
    const isAlreadyPresent = notifications?.find(item => item.preventAutoClose);

    if (!isPoliciesViewed && !isAlreadyPresent) {
      dispatch(addNotification({
        title: <>By using this website you agree to our use of Cookies to personalize your experience (learn more in <Link to="/docs/privacy-policy">Privacy Policy</Link>)</>,
        action: 'OK',
        preventAutoClose: true,
        onActionClick: (id: string) => {
          dispatch(removeNotification(id));
          storage.setItem('view-policies', 'true');
        },
        onClose: () => {
          storage.setItem('view-policies', 'true');
        }
      }));
    }
  }, []);
}
