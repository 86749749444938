import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

export const IntercomContext = React.createContext(false);

export function IntercomProvider({ children }) {
  const [isInit, setIsInit] = React.useState(false);

  const loggedIn: any = useSelector((state: IAppState) => state.loggedIn);

  useEffect(() => {
    if (loggedIn) {
      const interval = setInterval(() => {
        if (window.Intercom && !isInit) {
          window.Intercom('update', {
            email_hash: loggedIn.intercom_email_hash,
            user_hash: loggedIn.intercom_uid_hash,
            email: loggedIn.email,
            user_id: loggedIn.uid,
            uid: loggedIn.uid,
            name: `${loggedIn.firstname || ''} ${loggedIn.lastname || ''}`.trim(),
            custom_launcher_selector: '#intercom-button'
          });
          setIsInit(true);
          console.log('intercom inited');
          clearInterval(interval);
        }
      }, 1000);
    } else {
      const interval = setInterval(() => {
        if (window.Intercom && !isInit) {
          window.Intercom('update', {
            custom_launcher_selector: '#intercom-button'
          });
          setIsInit(true);
          console.log('intercom inited');
          clearInterval(interval);
        }
      }, 1000);
    }
  }, [loggedIn, isInit]);

  return <IntercomContext.Provider value={isInit}>{children}</IntercomContext.Provider>;
}
