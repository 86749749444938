import React from 'react';
import './footer-socials.scss';

export function FacebookFooterIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
      className='social-icon'
    >
      <rect width='50' height='50' rx='25' fill='#FAF5FF' />
      <path
        d='M22.6646 32.9167H25.998V26.2417H29.0013L29.3313 22.925H25.998V21.25C25.998 21.029 26.0858 20.8171 26.242 20.6608C26.3983 20.5045 26.6103 20.4167 26.8313 20.4167H29.3313V17.0834H26.8313C25.7262 17.0834 24.6664 17.5224 23.885 18.3038C23.1036 19.0852 22.6646 20.145 22.6646 21.25V22.925H20.998L20.668 26.2417H22.6646V32.9167Z'
        fill='var(--main-text-color)'
      />
    </svg>
  );
}

export function InstagramFooterIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none' className='social-icon'>
      <rect width='50' height='50' rx='25'  fill='#FAF5FF'  />
      <path
        d='M21.5013 16.6667H28.5013C31.168 16.6667 33.3346 18.8334 33.3346 21.5V28.5C33.3346 29.7819 32.8254 31.0113 31.919 31.9177C31.0126 32.8241 29.7832 33.3334 28.5013 33.3334H21.5013C18.8346 33.3334 16.668 31.1667 16.668 28.5V21.5C16.668 20.2181 17.1772 18.9888 18.0836 18.0823C18.99 17.1759 20.2194 16.6667 21.5013 16.6667ZM21.3346 18.3334C20.539 18.3334 19.7759 18.6494 19.2133 19.212C18.6507 19.7746 18.3346 20.5377 18.3346 21.3334V28.6667C18.3346 30.325 19.6763 31.6667 21.3346 31.6667H28.668C29.4636 31.6667 30.2267 31.3506 30.7893 30.788C31.3519 30.2254 31.668 29.4623 31.668 28.6667V21.3334C31.668 19.675 30.3263 18.3334 28.668 18.3334H21.3346ZM29.3763 19.5834C29.6526 19.5834 29.9175 19.6931 30.1129 19.8885C30.3082 20.0838 30.418 20.3488 30.418 20.625C30.418 20.9013 30.3082 21.1662 30.1129 21.3616C29.9175 21.5569 29.6526 21.6667 29.3763 21.6667C29.1 21.6667 28.8351 21.5569 28.6397 21.3616C28.4444 21.1662 28.3346 20.9013 28.3346 20.625C28.3346 20.3488 28.4444 20.0838 28.6397 19.8885C28.8351 19.6931 29.1 19.5834 29.3763 19.5834ZM25.0013 20.8334C26.1064 20.8334 27.1662 21.2723 27.9476 22.0537C28.729 22.8351 29.168 23.895 29.168 25C29.168 26.1051 28.729 27.1649 27.9476 27.9463C27.1662 28.7277 26.1064 29.1667 25.0013 29.1667C23.8962 29.1667 22.8364 28.7277 22.055 27.9463C21.2736 27.1649 20.8346 26.1051 20.8346 25C20.8346 23.895 21.2736 22.8351 22.055 22.0537C22.8364 21.2723 23.8962 20.8334 25.0013 20.8334ZM25.0013 22.5C24.3383 22.5 23.7024 22.7634 23.2335 23.2323C22.7647 23.7011 22.5013 24.337 22.5013 25C22.5013 25.6631 22.7647 26.2989 23.2335 26.7678C23.7024 27.2366 24.3383 27.5 25.0013 27.5C25.6643 27.5 26.3002 27.2366 26.7691 26.7678C27.2379 26.2989 27.5013 25.6631 27.5013 25C27.5013 24.337 27.2379 23.7011 26.7691 23.2323C26.3002 22.7634 25.6643 22.5 25.0013 22.5Z'
        fill='var(--main-text-color)'
      />
    </svg>
  );
}

export function LinkedInFooterIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none' className='social-icon'>
      <rect width='50' height='50' rx='25' fill='#FAF5FF'  />
      <path
        d='M33.3005 26.4325V32.5817H29.7355V26.8442C29.7355 25.4025 29.2196 24.4192 27.9296 24.4192C26.9446 24.4192 26.358 25.0825 26.1005 25.7234C26.0063 25.9525 25.9821 26.2717 25.9821 26.5925V32.5817H22.4155C22.4155 32.5817 22.4638 22.865 22.4155 21.8575H25.9821V23.3775L25.9588 23.4125H25.9821V23.3775C26.4555 22.6484 27.3013 21.6059 29.1955 21.6059C31.5413 21.6059 33.3005 23.1392 33.3005 26.4325ZM18.6855 16.6884C17.4663 16.6884 16.668 17.4884 16.668 18.5409C16.668 19.57 17.443 20.3942 18.6388 20.3942H18.6621C19.9063 20.3942 20.6788 19.57 20.6788 18.5409C20.6571 17.4884 19.9063 16.6884 18.6855 16.6884ZM16.8796 32.5817H20.4446V21.8575H16.8796V32.5817Z'
        fill='var(--main-text-color)'
      />
    </svg>
  );
}

export function TwitterFooterIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50' fill='none' className='social-icon'>
      <rect width='50' height='50' rx='25' fill='#FAF5FF' />
      <path
        d='M26.6175 23.66L32.9463 16.25H31.4462L25.9525 22.6837L21.5625 16.25H16.5L23.1375 25.98L16.5 33.75H18L23.8025 26.955L28.4388 33.75H33.5012L26.6175 23.66ZM24.5638 26.065L23.8913 25.0962L18.54 17.3875H20.8438L25.1613 23.6088L25.8337 24.5775L31.4475 32.665H29.1437L24.5638 26.065Z'
        fill='var(--main-text-color)'
      />
    </svg>
  )
}
