import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

type BurgerIconProps = {
  onClick?: () => void;
};

export function BurgerIcon({ onClick }: BurgerIconProps) {
  const isDarkMode = useSelector((state:IAppState) => state.settings.isDarkTheme);
  const fillColor = isDarkMode ? 'white' : '#363C43';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='10'
      viewBox='0 0 20 10'
      fill='none'
      onClick={onClick}
    >
      <rect width='20' height='2' rx='1' fill={fillColor} />
      <rect x='1' y='4' width='18' height='2' rx='1' fill={fillColor} />
      <rect x='4' y='8' width='12' height='2' rx='1' fill={fillColor} />
    </svg>
  );
}
