import React, { CSSProperties, forwardRef } from 'react';
import { SpinningCircle } from 'layout/components/spinning-circle';
import './IconBase.scss';
import classNames from 'utils/class-names';

type TableIconBaseProps = {
  children: JSX.Element;
  pending?: boolean;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
  className?: string;
  ref?: any;
};

function IconBaseComponent(
  {
    children,
    pending,
    style,
    onClick,
    className: classNameProp,
    onMouseEnter,
    onMouseLeave
  }: TableIconBaseProps,
  ref: any
) {
  const className = classNames({
    'table-icon-base': true,
    'table-icon-base_pending': !!pending,
    [classNameProp]: !!classNameProp
  });

  return (
    <div
      className={className}
      onClick={onClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      {pending && <SpinningCircle />}
      {React.cloneElement(children, {
        pending
      })}
    </div>
  );
}

export const IconBase = forwardRef(IconBaseComponent);
