import React, { forwardRef } from 'react';
import RcDropdown from 'layout/components/rc-dropdown';
import classNames from 'utils/class-names';
import './dropdown.scss';
import { DropdownOverlay } from 'layout/components/dropdown/dropdown-overlay';

type DropdownProps = {
  transitionName?: string;
  arrow?: boolean;
  placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
  disabled?: boolean;
  trigger?: ('click' | 'hover' | 'contextMenu')[];
  overlayClassName?: string;
  overlay: React.ReactElement;
  children: React.ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  visible?: boolean;
  useMask?: boolean;
  hideAction?: string[];
  onVisibleChange?: (visible: boolean) => void;
  overlayType?: 'fixed' | 'absolute';
};

const getPrefixCls = () => 'app';

function DropdownF(props: DropdownProps, ref: any) {
  const getTransitionName = () => {
    const rootPrefixCls = getPrefixCls();
    const { placement = '', transitionName } = props;
    if (transitionName !== undefined) {
      return transitionName;
    }
    if (placement.indexOf('top') >= 0) {
      return `${rootPrefixCls}-slide-down`;
    }
    return `${rootPrefixCls}-slide-up`;
  };

  const { arrow, disabled, trigger, placement, overlayType, children } = props;

  const triggerActions = disabled ? [] : trigger;
  let alignPoint;
  if (triggerActions && triggerActions.indexOf('contextMenu') !== -1) {
    alignPoint = true;
  }

  const renderOverlay = (prefixCls: string) => {
    // rc-dropdown already can process the function of overlay, but we have check logic here.
    // So we need render the element to check and pass back to rc-dropdown.
    const { overlay } = props;

    let overlayNode = overlay;
    overlayNode = React.Children.only(
      typeof overlayNode === 'string' ? <span>{overlayNode}</span> : overlayNode
    );

    const overlayProps = overlayNode.props;
    const { selectable = false, expandIcon } = overlayProps;

    const overlayNodeExpandIcon =
      typeof expandIcon !== 'undefined' && React.isValidElement(expandIcon) ? (
        expandIcon
      ) : (
        <span className={`${prefixCls}-menu-submenu-arrow`}>
          {/*<RightOutlined className={`${prefixCls}-menu-submenu-arrow-icon`} />*/}
        </span>
      );

    const fixedModeOverlay =
      typeof overlayNode.type === 'string'
        ? overlayNode
        : React.cloneElement(overlayNode, {
            mode: 'vertical',
            selectable,
            expandIcon: overlayNodeExpandIcon
          });

    return fixedModeOverlay as React.ReactElement;
  };

  const child = React.Children.only(children) as React.ReactElement<any>;
  const dropdownTrigger = React.cloneElement(child, {
    className: classNames({
      'app-dropdown-trigger': true,
      [child.props.className]: !!child.props.className
    }),
    disabled
  });
  const overlayClassName = overlayType === 'fixed' ? 'app-dropdown_fixed' : undefined;

  return (
    <RcDropdown
      arrow={arrow}
      alignPoint={alignPoint}
      mouseEnterDelay={0.15}
      mouseLeaveDelay={0.1}
      {...props}
      ref={ref}
      overlayClassName={overlayClassName}
      prefixCls='app-dropdown'
      transitionName={getTransitionName()}
      trigger={triggerActions}
      overlay={() => renderOverlay('app-dropdown')}
      placement={placement || 'bottomLeft'}
    >
      {dropdownTrigger}
    </RcDropdown>
  );
}

export const Dropdown = forwardRef(DropdownF);

(Dropdown as any).Overlay = DropdownOverlay;
