import React from 'react';
import {LinkItem} from './LinkItem';
import {Divider} from './Divider';
import { ItemWithSubItem } from './ItemWithSubItem';
import { MobileLinkItem } from './MobileLinkItem';
import {Item} from './Item';
import classNames from '../../../utils/class-names';

type AppMenuProps = {
  children: React.ReactNode;
  className?: string;
};

export default function AppMenu({children, className}: AppMenuProps) {
  const classes = classNames({
    'mobile-menu': true,
    [className]: !!className
  });

  return (
    <ul className={classes}>
      {children}
    </ul>
  )
}

AppMenu.LinkItem = LinkItem;
AppMenu.MobileLinkItem = MobileLinkItem;
AppMenu.Divider = Divider;
AppMenu.ItemWithSubItem = ItemWithSubItem;
AppMenu.Item = Item;
