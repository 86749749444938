import React from 'react';
import './BottomFooter.scss';

import FooterLogoBlock from './FooterLogoBlock';
import FooterSettings from './FooterSettings';
import FooterPolicy from './FooterPolicy';

type BottomFooterProps = {
  bordered?: boolean;
};

export const BottomFooter = ({ bordered }: BottomFooterProps) => {
  return (
    <footer className='footer'>
      <div className='footer__wrapper'>
        <div className='footer__block'>
          <FooterLogoBlock />
          <FooterSettings />
        </div>
        <div className='footer__policy'>
          <FooterPolicy />
        </div>
      </div>
    </footer>
  );
};