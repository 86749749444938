import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

export function MenuMinus() {
    const isDarkMode = useSelector((state:IAppState) => state.settings.isDarkTheme);
    const strokeColor = isDarkMode ? '#008dff' : '#000DFF';

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path d='M18 12L6 12' stroke={strokeColor} strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
}
