import React from 'react';
import classNames from 'utils/class-names';
import './avatar.scss';

type AvatarProps = {
  size?: number;
  children?: React.ReactNode;
  className?: string;
  src?: string;
};

export function Avatar({ children, className: classNameProps, size, src }: AvatarProps) {
  const className = classNames({
    avatar: true,
    [classNameProps]: !!classNameProps
  });

  return (
    <span className={className} style={{ width: size, height: size }}>
      {src ? <img src={src} alt='avatar-img' /> : children}
    </span>
  );
}
