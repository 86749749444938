import { IAppState } from 'interfaces';
import React from 'react';
import { useSelector } from 'react-redux';

export function MenuArrow({className}: {className?: string})  {
    const isDarkMode = useSelector((state:IAppState) => state.settings.isDarkTheme);
    const strokeColor = isDarkMode ? 'white' : '#363C43';

  return (
    <svg className={className} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g opacity='0.5'>
        <path d='M9 6L15 12L9 18' stroke={strokeColor} strokeWidth='2' />
      </g>
    </svg>
  );
}
