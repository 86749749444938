import React, { CSSProperties } from 'react';

import './Logo.scss';
import { useLocation } from 'hooks/useLocation';
import { usePush } from 'hooks/usePush';

type Props = {
  src?: string;
  width?: number | string;
  height?: number | string;
  alt?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
};

const Logo = ({ alt = '', height, width, src, children, style = {} }: Props) => {
  const pathname = useLocation().pathname;
  const push = usePush();

  const handleClick = () => {
    const to = pathname && pathname.startsWith('/render') && !pathname.endsWith('render') ? '/render' : '/';

    push(to);
  };

  return (
    <div className='logo' style={style} onClick={handleClick}>
      {children}
    </div>
  );
};

export default Logo;
