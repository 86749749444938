import React, { CSSProperties } from 'react';
import classNames from 'utils/class-names';
import './app-button.scss';

type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  className?: string;
  id?: string;
  htmlType?: 'submit' | 'reset' | 'button';
  type?: 'primary' | 'secondary';
  block?: boolean;
  style?: CSSProperties;
  icon?: React.ReactNode;
  disabled?: boolean;
  size?: 's' | 'm';
  loading?: boolean;
  title?: string;
};

function ButtonComponent({
  children,
  onClick,
  className: propClassName,
  htmlType,
  block,
  style,
  icon,
  disabled,
  size = 'm',
  loading = false,
  title,
  id
}: ButtonProps, ref) {
  const className = classNames({
    'app-button': true,
    'app-button_block': block,
    [propClassName]: !!propClassName,
    'app-button_disabled': disabled || loading,
    'app-button_loading': loading,
    [`app-button_size_${size}`]: !!size
  });

  return (
    <button
      ref={ref}
      id={id}
      title={title}
      style={style}
      type={htmlType}
      className={className}
      onClick={(e) => {
        if (!disabled && !loading && onClick) {
          onClick(e);
        }
      }}
      disabled={disabled || loading}
    >
      {icon}
      <span>{children}</span>
    </button>
  );
}

export const Button = React.forwardRef(ButtonComponent)

Button.displayName = 'Button';
