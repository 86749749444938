import React from 'react';
import classNames from 'utils/class-names';

import './arrow-right.scss';

type ArrowRightProps = {
  className?: string;
  width?: number;
  height?: number;
};

export function ArrowRight({ className: propClassName, width = 30, height = 30 }: ArrowRightProps) {
  const className = classNames({
    'arrow-right': true,
    [propClassName]: !!propClassName
  });

  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox={viewBox} fill='none' className={className}>
      <rect y={height} width={width} height={height} rx={height / 2} transform={`rotate(-90 0 ${height})`} fill='none' />
      <path d='M11.25 7.5L18.75 15L11.25 22.5' stroke='#363C43' strokeWidth='2' />
    </svg>
  );
}
