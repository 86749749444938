import React from 'react';
import './toggle-button.scss';
import classNames from 'utils/class-names';

type ToggleButtonProps = {
  isChecked: boolean;
  size: 'XS' | 'S' | 'M' | 'L';
  onChange: (isChecked: boolean) => void;
  id: string;
};

export function ToggleButton({ isChecked, size, onChange, id }: ToggleButtonProps) {
  const handleToggleChange = () => {
    if (onChange) {
      onChange(!isChecked);
    }
  };


  const classNameToggle = classNames({
    toggle: true,
    toggle_xs: size === 'XS',
    toggle_small: size === 'S',
    toggle_medium: size === 'M',
    toggle_large: size === 'L',
  });

  return (
    <label className={classNameToggle} htmlFor={id}>
      <input
        className="toggle__input"
        name=''
        type='checkbox'
        id={id}
        checked={isChecked}
        onChange={handleToggleChange}
      />
      <div className="toggle__fill"></div>
    </label>
  );
}
