import React from 'react';
import { FacebookFooterIcon, InstagramFooterIcon, LinkedInFooterIcon, TwitterFooterIcon } from '../icons/footer-socials';
import './SocialIcons.scss';

const icons = [
  {
    name: 'instagram',
    link: 'https://www.instagram.com/mubert.app/',
    icon: InstagramFooterIcon
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/mubertapp',
    icon: FacebookFooterIcon
  },
  {
    name: 'linkedin',
    link: 'https://linkedin.com/company/mubert/',
    icon: LinkedInFooterIcon
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/mubertapp',
    icon: TwitterFooterIcon
  }
];

export default function SocialIcons() {
  return (
    <div className='social-icons'>
      {icons.map(({ name, link, icon: IconComponent }) => (
        <a href={link} key={name} target='_blank' rel='noopener noreferrer nofollow'>
          <IconComponent />
        </a>
      ))}
    </div>
  );
}
