import React, { useEffect } from 'react';

export const getCoordinates = ({
  touches,
  changedTouches,
  clientX,
  clientY
}: Partial<TouchEvent & PointerEvent>) =>
  touches && changedTouches
    ? {
        clientX: (touches[0] || changedTouches[0]).clientX,
        clientY: (touches[0] || changedTouches[0]).clientY
      }
    : { clientX, clientY };

export type SwipeableProps = {
  /**
   * Число пикселей, на которое нужно сместиться, чтобы запустить функцию по свайпу
   */
  delta?: number;

  /**
   * Функция запускаемая по свайпу.
   */
  onSwipe: (direction: 'top' | 'right' | 'bottom' | 'left') => void;

  /**
   * Дочерний компонент представленный в виде единичного элемента
   * TODO: // тут должны быть такие children что у ниъ есть onMouseDown onTouchStart
   */
  children: any;
};



function Swipeable({children, delta = 100, onSwipe}: SwipeableProps) {
  let swipeStartX = React.useRef(0);
  let swipeStartY = React.useRef(0);
  let deltaX = React.useRef(0);
  let deltaY = React.useRef(0);

  const removeListeners = () => {
    document.removeEventListener('mouseup', handleSwipeEnd);
    document.removeEventListener('mouseup', removeListeners);
    document.removeEventListener('touchend', handleSwipeEnd);
    document.removeEventListener('touchend', removeListeners);
    document.removeEventListener('touchcancel', removeListeners);
    swipeStartX.current = 0;
    swipeStartY.current= 0;
    deltaX.current = 0;
    deltaY.current = 0;
  };

  const handleSwipeStart = (event) => {
    const { clientX, clientY } = getCoordinates(event);

    swipeStartX.current = clientX;
    swipeStartY.current = clientY;
  };

  const handleSwipeEnd = (event) => {
    const { clientX, clientY } = getCoordinates(event);

    deltaX.current = swipeStartX.current - clientX;
    deltaY.current = swipeStartY.current - clientY;

    if (typeof onSwipe === 'function') {
      if (deltaX.current > delta) {
        onSwipe('left');
      } else if (deltaX.current < -delta) {
        onSwipe('right');
      } else if (deltaY.current > delta) {
        onSwipe('top');
      } else if (deltaY.current < -delta) {
        onSwipe('bottom');
      }
    }
  };

  const handleMouseDown = (event) => {
    if (children.props.onMouseDown) {
      children.props.onMouseDown(event);
    }
    handleSwipeStart(event);

    document.addEventListener('mouseup', handleSwipeEnd);
    document.addEventListener('mouseup', removeListeners);
  };

  const handleTouchStart = (event) => {
    if (event.touches && event.touches.length > 1) {
      return;
    }

    if (children.props.onTouchStart) {
      children.props.onTouchStart(event);
    }

    handleSwipeStart(event);

    document.addEventListener('touchend', handleSwipeEnd);
    document.addEventListener('touchend', removeListeners);
    document.addEventListener('touchcancel', removeListeners);
  };

  useEffect(() => {
    return () => {
      removeListeners();
    };
  }, []);

  return React.cloneElement(children, {
    onMouseDown: handleMouseDown,
    onTouchStart: handleTouchStart
  });
}
export default Swipeable;
