import React, { useEffect } from 'react';

import classNames from 'utils/class-names';
import Swipeable from '../../swipeable';
import { CloseIcon } from 'layout/components/icons/close-icon';

import { isNodeOutsideElement } from 'utils/window';
import './notification.scss';

export type NotificationProps = {
  status?: 'error' | 'fail' | 'ok';
  visible?: boolean;
  offset?: number;
  stickTo?: 'left' | 'right';
  hasCloser?: boolean;
  children?: React.ReactNode;
  className?: string;
  animated?: boolean;
  title?: React.ReactNode;
  action?: React.ReactNode;
  autoCloseDelay?: number;
  onCloseTimeout?: () => void;
  onCloserClick?: (event?: React.MouseEvent<any>) => void;
  onKeyDown?: (event?: React.KeyboardEvent<any>) => void;
  onMouseEnter?: (event?: React.MouseEvent<any>) => void;
  onMouseLeave?: (event?: React.MouseEvent<any>) => void;
  onClickOutside?: (event?: React.MouseEvent<any>) => void;
  onClick?: (event?: React.MouseEvent<any>) => void;
  onActionClick?: (event?: React.MouseEvent<any>) => void;
  preventAutoClose?: boolean;
};

export function Notification({
  children,
  visible,
  title,
  status,
  animated = true,
  hasCloser = true,
  onClickOutside,
  autoCloseDelay = 7000,
  onCloseTimeout,
  onCloserClick,
  stickTo = 'left',
  offset = 12,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onKeyDown,
  action,
  onActionClick,
  preventAutoClose
}: NotificationProps) {
  const rootRef = React.useRef(null);
  const closeTimeout = React.useRef(null);
  const clickEventBindTimeout = React.useRef(null);
  const isWindowClickBinded = React.useRef(false);
  // const [hovered, setHovered] = React.useState(false);

  const handleWindowClick = (event) => {
    if (onClickOutside && rootRef.current && isNodeOutsideElement(event.target, rootRef.current)) {
      onClickOutside(event);
    }
  };

  const ensureClickEvent = (isDestroy?) => {
    const isNeedBindEvent = isDestroy === undefined ? visible : !isDestroy;

    // We need timeouts to not to catch the event that causes
    // popup opening (because it propagates to the `window`).
    if (clickEventBindTimeout.current) {
      clearTimeout(clickEventBindTimeout.current);
      clickEventBindTimeout.current = null;
    }

    clickEventBindTimeout.current = setTimeout(() => {
      if (!isWindowClickBinded.current && isNeedBindEvent) {
        window.addEventListener('click', handleWindowClick);
        window.addEventListener('touchend', handleWindowClick);
        isWindowClickBinded.current = true;
      } else if (isWindowClickBinded.current && !isNeedBindEvent) {
        window.removeEventListener('click', handleWindowClick);
        window.removeEventListener('touchend', handleWindowClick);
        isWindowClickBinded.current = false;
      }
    }, 0);
  };

  const handleCloserClick = (event?) => {
    if (onCloserClick) {
      onCloserClick(event);
    }
  };

  const handleSwipe = (direction) => {
    if (direction === 'left' || direction === 'right' || direction === 'bottom') {
      handleCloserClick();
    }
  };

  const startCloseTimer = () => {
    closeTimeout.current = setTimeout(() => {
      if (onCloseTimeout) {
        onCloseTimeout();
      }
    }, autoCloseDelay);
  };

  const stopCloseTimer = () => {
    clearTimeout(closeTimeout.current);
    closeTimeout.current = null;
  };

  // const getPosition = () => {
  //   return { top: offset };
  // };

  const handleMouseEnter = (event) => {
    // setHovered(true)

    if (!preventAutoClose) {
      stopCloseTimer();
    }

    if (onMouseEnter) {
      onMouseEnter(event);
    }
  };

  const handleMouseLeave = (event) => {
    // setHovered(false);

    if (!preventAutoClose) {
      stopCloseTimer();
      startCloseTimer();
    }

    if (onMouseLeave) {
      onMouseLeave(event);
    }
  };

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }
  };

  const handleActionClick = (event) => {
    event.preventDefault();
    if (onActionClick) {
      onActionClick();
    }
  };

  useEffect(() => {
    if (!preventAutoClose) {
      startCloseTimer();
    }

    if (onClickOutside) {
      ensureClickEvent();
    }

    return () => {
      if (!preventAutoClose) {
        stopCloseTimer();
      }

      if (onClickOutside) {
        ensureClickEvent(true);
      }
    };
  }, []);

  useEffect(() => {
    ensureClickEvent();
  }, [onClickOutside]);

  useEffect(() => {
    ensureClickEvent(visible);
  }, [visible]);

  return (
    <Swipeable onSwipe={handleSwipe}>
      <div
        ref={rootRef}
        className={classNames({
          notification: true,
          notification_visible: visible,
          notification_animated: animated,
          [`notification_${status}`]: !!status,
          // notification_hovered: hovered,
          'notification_has-closer': hasCloser,
          [`notification_stick-to_${stickTo}`]: !!stickTo
        })}
        // style={getPosition()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        {title && <div className='notification__title'>{title}</div>}
        {action && (
          <div className='notification__action' onClick={handleActionClick}>
            {action}
          </div>
        )}
        {children && <div className='notification__content'>{children}</div>}
        {hasCloser && <CloseIcon onClick={handleCloserClick} className='notification__closer' />}
      </div>
    </Swipeable>
  );
}
