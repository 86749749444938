import React from 'react';
import classNames from 'utils/class-names';
import './link-button.scss';

type LinkButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  type?: 'primary' | 'secondary';
}

export function LinkButton({className: propClassName, children, onClick, type = 'primary'}: LinkButtonProps) {
  const className = classNames({
    'link-button': true,
    [propClassName]: !!propClassName,
    'link-button_primary': type === 'primary',
    'link-button_secondary': type === 'secondary'
  });

  return <button type='button' onClick={onClick} className={className}>{children}</button>
}

LinkButton.displayName = 'LinkButton';
