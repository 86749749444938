import React from 'react';
import classNames from 'utils/class-names';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

export function NewBadge({ className }: { className?: string }) {
  const idDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme);
  const classNameList = classNames({
    'new-badge-playlist': true,
    [className]: !!className
  });

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='39' height='17' viewBox='0 0 39 17' fill='none' className={classNameList}>
      <rect
        x='0.5'
        y='0.5'
        width='38'
        height='16'
        rx='3.5'
        stroke={idDarkTheme ? '#FF00E5' : 'url(#paint1_linear_12328_2893)'}
      />
      <path
        d='M14.4773 4.27273V13H13.4545L8.69886 6.14773H8.61364V13H7.55682V4.27273H8.57955L13.3523 11.142H13.4375V4.27273H14.4773ZM19.1999 13.1364C18.5692 13.1364 18.0252 12.9972 17.5678 12.7188C17.1133 12.4375 16.7624 12.0455 16.5153 11.5426C16.271 11.0369 16.1488 10.4489 16.1488 9.77841C16.1488 9.10795 16.271 8.51705 16.5153 8.00568C16.7624 7.49148 17.1062 7.09091 17.5465 6.80398C17.9897 6.5142 18.5067 6.36932 19.0977 6.36932C19.4386 6.36932 19.7752 6.42614 20.1076 6.53977C20.44 6.65341 20.7425 6.83807 21.0153 7.09375C21.288 7.34659 21.5053 7.68182 21.6673 8.09943C21.8292 8.51705 21.9102 9.03125 21.9102 9.64205V10.0682H16.8647V9.19886H20.8874C20.8874 8.82955 20.8136 8.5 20.6658 8.21023C20.521 7.92045 20.3136 7.69176 20.0437 7.52415C19.7766 7.35653 19.4613 7.27273 19.0977 7.27273C18.6971 7.27273 18.3505 7.37216 18.0579 7.57102C17.7681 7.76705 17.5451 8.02273 17.3888 8.33807C17.2326 8.65341 17.1545 8.99148 17.1545 9.35227V9.93182C17.1545 10.4261 17.2397 10.8452 17.4102 11.1889C17.5835 11.5298 17.8235 11.7898 18.1303 11.9688C18.4371 12.1449 18.7937 12.233 19.1999 12.233C19.4641 12.233 19.7028 12.196 19.9158 12.1222C20.1317 12.0455 20.3178 11.9318 20.4741 11.7812C20.6303 11.6278 20.7511 11.4375 20.8363 11.2102L21.8079 11.483C21.7056 11.8125 21.5337 12.1023 21.2923 12.3523C21.0508 12.5994 20.7525 12.7926 20.3974 12.9318C20.0423 13.0682 19.6431 13.1364 19.1999 13.1364ZM24.9229 13L22.9286 6.45455H23.9854L25.4002 11.4659H25.4684L26.8661 6.45455H27.94L29.3207 11.4489H29.3888L30.8036 6.45455H31.8604L29.8661 13H28.8775L27.4457 7.97159H27.3434L25.9116 13H24.9229Z'
        fill={idDarkTheme ? '#FF00E5' : 'url(#paint0_linear_12328_2893)'}
      />
      <defs>
        <linearGradient
          id='paint0_linear_12328_2893'
          x1='41.9043'
          y1='-9.35'
          x2='18.8849'
          y2='16.4512'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#000DFF' />
          <stop offset='1' stopColor='#FF00E5' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_12328_2893'
          x1='34.4362'
          y1='-9.35'
          x2='12.2168'
          y2='7.25302'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#000DFF' />
          <stop offset='1' stopColor='#FF00E5' />
        </linearGradient>
      </defs>
    </svg>
  );
}
