import React from 'react';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';
import classNames from 'utils/class-names';
import './notification-manager.scss';

import {Notification} from '../notification';

type NotificationManagerProps = {
  stickTo?: 'left' | 'right';
  /** Нотификации */
  notifications?: Array<{
    id: string;
    status?: 'error' | 'fail' | 'ok';
    hasCloser?: boolean;
    className?: string;
    title: React.ReactNode;
    action?: string;
    actionClick?: (event?: React.MouseEvent<any>) => void;
    // onCloserClick: (event?: React.MouseEvent<any>) => void;
    // onMouseEnter: Function;
    onClickOutside?: (event?: React.MouseEvent<any>) => void;
    onClick?: (event?: React.MouseEvent<any>) => void;
    disableClosingByTimeout?: boolean;
    icon?: React.ReactNode;
    preventAutoClose?: boolean;
    onClose?: Function;
  }>
  onRemoveNotification: Function;
  enterTimeout?: number;
  leaveTimeout?: number;
  isAnimated?: boolean;
};

export function NotificationManager({
  stickTo = 'right',
  enterTimeout = 400,
  leaveTimeout = 400,
  notifications: propsNotifications = [],
  isAnimated = true,
  onRemoveNotification
}: NotificationManagerProps) {
  const handleCloserClick = (id) => {
    if (propsNotifications.some(notification => notification.id === id)) {
      const notification = propsNotifications.find(item => item.id === id);

      if (notification.onClose) {
        notification.onClose();
      }

      onRemoveNotification(id);
    }
  }

  const className = classNames({
    'notification-manager': true,
    [`notification-manager_stick-to_${stickTo}`]: !!stickTo
  });

  const renderedNotifications = propsNotifications.map(notification => (
    <CSSTransition
      key={ notification.id }
      timeout={ { exit: leaveTimeout, enter: enterTimeout } }
      classNames={ `notification-${stickTo}` }
    >
      <Notification
        { ...notification }
        visible={ true }
        offset={ 0 }
        stickTo={ stickTo }
        onCloserClick={ () => handleCloserClick(notification.id) }
        onCloseTimeout={ () => handleCloserClick(notification.id) }
      />
    </CSSTransition>
  ));

  const notificationsGroup = isAnimated
    ? (
      <TransitionGroup>
        { renderedNotifications }
      </TransitionGroup>
    )
    : renderedNotifications;

  return (
    <div className={ className }>
      { notificationsGroup }
    </div>
  );
}
