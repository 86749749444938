const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function unixToDdMonthYyyy(unixTimestamp: number) {
  let date = new Date(unixTimestamp * 1000);
  let monthName = months[date.getMonth()];
  let day = date.getDate().toString().padStart(2, '0');
  let year = date.getFullYear();

  return day + ' ' + monthName + ' ' + year;
}

export function unixToDdMmYyyy(unixTimestamp: number) {
  let date = new Date(unixTimestamp * 1000);
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let year = date.getFullYear();

  return month + '/' + day + '/' + year;
}

export function unixToMmDdYyyy(unixTimestamp: number) {
  let date = new Date(unixTimestamp * 1000);
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0');
  let year = date.getFullYear();

  return day + '/' + month + '/' + year;
}

export function unixToMmmmDd(unixTimestamp: number) {
  let date = new Date(unixTimestamp * 1000);
  let day = date.getDate().toString().padStart(2, '0');
  let month = months[date.getMonth()];

  return month + " " +  day;
}
