import React from 'react';
import './expand-icon.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IAppState } from 'interfaces';

type ExpandIconProps = {
  onClick?: () => void;
  className?: string;
  isExpanded?: boolean;
};

export function ExpandIcon({ onClick, isExpanded, className: propClassName }: ExpandIconProps) {

  const isDarkTheme = useSelector((state: IAppState) => state.settings.isDarkTheme)

  const className = classNames({
    'expand-icon': true,
    'expand-icon_opened': isExpanded,
    [propClassName]: !!propClassName
  });

  return (
    <div className={className}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        onClick={onClick}
      >
        <rect
          x='30'
          y='30'
          width='30'
          height='30'
          rx='15'
          transform='rotate(-180 30 30)'
          fill={isDarkTheme ? "#2A2F34": '#FAF5FF'}
        />
        <path d='M7.5 18.75L15 11.25L22.5 18.75' stroke={isDarkTheme ? 'white' :'#363C43'} strokeWidth='2' />
      </svg>
    </div>
  );
}
