import { storage } from 'utils/storage';
import {
  RESET_IS_HIDE_BY_USER,
  TOGGLE_GENERATOR,
  ADD_GENERATED_TRACK,
  REMOVE_GENERATED_TRACK,
  HIDE_BY_USER
} from './types';
import { IAppState } from 'interfaces';

export const toggleGenerator = (payload?: boolean) => ({
  type: TOGGLE_GENERATOR,
  payload
});

// export const toggleGeneratorByClick = (payload?: boolean) => (dispatch, getState: () => IAppState) => {
//   dispatch({
//     type: TOGGLE_GENERATOR_BY_CLICK,
//     payload
//   });
//
//   storage.setItem('is-generator-hidden', !getState().generator.isOpened);
// }

export const resetIsHideByUser = () => ({
  type: RESET_IS_HIDE_BY_USER
});

export const hideByUser = () => (dispatch) => {
  dispatch ({
    type: HIDE_BY_USER
  });

  storage.setItem('is-generator-hidden', 'true');
};

export const addTrackToGenerating = (sessionId: string) => (dispatch, getState: () => IAppState) => {
  dispatch({
    type: ADD_GENERATED_TRACK,
    payload: sessionId
  });

  const ids = getState().generator.generatedTracks;

  storage.setItem('generated-track-ids', JSON.stringify(ids));
};

export const removeTrackFromGenerating = (sessionId: string) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_GENERATED_TRACK,
    payload: sessionId
  });

  const ids = getState().generator.generatedTracks;

  storage.setItem('generated-track-ids', JSON.stringify(ids));
};
