import React, { ReactNode } from 'react';
import { useIsBannerVisible } from 'hooks/useIsBannerVisible';
import { useHandleLoginOrSignUp } from 'hooks/useHandleLoginOrSignUp';
import { useHandlePurchaseEvent } from 'hooks/useHandlePurchaseEvent';
import { useIsBodyScrolled } from 'hooks/useIsBodyScrolled';
import { useHandleLocationChange } from 'hooks/useHandleLocationChange';
import { useShowCookieNotification } from 'hooks/useShowCookieNotification';
import className from 'utils/class-names';
import { Notifications } from 'layout/components/notifications/notifications';
import { IntercomProvider } from 'layout/components/intercom-provider';
import { useIsBlackFridayBannerVisible } from '../../redux/pricing-info/selectors';
import './BaseLayout.scss';

type Props = {
  nav: ReactNode;
  bottomNav?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  player?: ReactNode;
};

const BaseLayout = ({ nav, bottomNav, footer, player, children }: Props) => {
  const isBodyScrolled = useIsBodyScrolled();
  const isBlackFridayVisible = useIsBlackFridayBannerVisible();

  // const dispatch = useDispatch();
  // const isGeneratorHidden = useAppSelector(state => state.generator.isHideByUser);
  useHandleLocationChange();
  useShowCookieNotification();
  useHandlePurchaseEvent();
  useHandleLoginOrSignUp();
  const isBannerVisible = useIsBannerVisible();

  const layoutClassNames = className({
    layout: true,
    'layout_with-banner': isBannerVisible,
    'layout_black-friday': isBlackFridayVisible,
    'layout__scrolled': isBodyScrolled
  });

  return (
    <IntercomProvider>
      <div className={layoutClassNames} id='scrollToUp'>
        {nav}
        {children}
        {bottomNav}
        {footer}
        {player}
        <Notifications />
      </div>
    </IntercomProvider>
  );
};

export default BaseLayout;
